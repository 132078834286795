import { Text } from "@fluentui/react";
import { BookInformation24Regular } from "@fluentui/react-icons";

import styles from "./UserGuideButton.module.css";

interface Props {
    className?: string;
    onClick: () => void;
}

export const UserGuideButton = ({ className, onClick }: Props) => {
    return (
        <div className={`${styles.container} ${className ?? ""}`} onClick={onClick}>
            <BookInformation24Regular />
            <Text className={styles.container}>{"User Guide"}</Text>
        </div>
    );
};
