import { WeatherSunny16Regular, WeatherMoon16Regular } from "@fluentui/react-icons";
import styles from "./ThemeToggleButton.module.css";

interface Props {
    theme: string;
    toggleTheme: () => void;
}

export const ThemeToggleButton = ({ theme, toggleTheme }: Props) => {
    const isDarkTheme = theme === 'dark';
    const buttonClass = `${styles.themeToggleButton} ${isDarkTheme ? styles.dark : ''}`;
    const sliderClass = `${styles.slider} ${isDarkTheme ? styles.darkSlider : ''}`;
    return (
        <button onClick={toggleTheme} className={buttonClass}>
            <WeatherSunny16Regular className={styles.sunIcon} />
            <WeatherMoon16Regular className={styles.moonIcon} />
            <div className={sliderClass}></div>
        </button>
    );
};