import React, { useState } from 'react';
import styles from './PPLDisclaimer.module.css';
import { ShowPreviewFeatures } from "../../config/frontendconfig";
import { ArrowUp28Regular, ArrowDown28Regular, Warning24Regular } from "@fluentui/react-icons";

interface Props {
    isVisible: boolean;
    toggleVisibility: () => void;
}

export const PPLDisclaimer: React.FC<Props> = ({ isVisible, toggleVisibility }) => {
    const [showPreviewFeatures, setShowPreviewFeatures] = useState<boolean>(ShowPreviewFeatures === "true");

    const expandedClass = showPreviewFeatures ? styles.expandedPreview : styles.expanded;

    return (
        <div
            className={`${styles.promptGuidance} ${isVisible ? expandedClass : styles.collapsed}`}
        >
            <div className={styles.headingContainer} onClick={toggleVisibility}>
                <Warning24Regular className={styles.warningAdjust} />
                <h3>PPL Disclaimer</h3>
                {isVisible ? (
                    <ArrowDown28Regular className={styles.iconAdjust} />
                ) : (
                    <ArrowUp28Regular className={styles.iconAdjust} />
                )}
            </div>
            {!showPreviewFeatures ? (
                <>
                    <p>This AI Chat Bot has access to Fonterra's Global Policies in PPL.</p>
                    <p>
                        <u>You are responsible for checking the completeness & accuracy of the Chat Bot's response</u> by using the link to the cited document in PPL.
                    </p>
                    <p>
                        If you require more information regarding Fonterra's Global Policies, contact <a href="mailto:policies@fonterra.com" className={styles.link}>policies@fonterra.com</a>
                    </p>
                </>
            ) : (
                <>
                    <p>This AI Chat Bot has access to Fonterra's Global Policies, Group Accounting Policies and Global Standards in PPL.</p>
                    <p>
                        <u>You are responsible for checking the completeness & accuracy of the Chat Bot's response</u> by using the link to the cited document in PPL.
                    </p>
                    <p>
                        If you require more information, contact <a href="mailto:policies@fonterra.com" className={styles.link}>policies@fonterra.com</a> (policies) or <a href="mailto:GlobalIMSTeam@fonterra.com" className={styles.link}>GlobalIMSTeam@fonterra.com</a> (standards)
                    </p>
                </>
            )}
        </div>
    );
};