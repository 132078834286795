import styles from "./Answer.module.css";
import { CopyBlock, dracula } from "react-code-blocks";

type HtmlParsedAnswer = {
    answerHtmlParts: (string | JSX.Element)[];
    citations: string[];
    followupQuestions: string[];
};

function parseCodeBlocks(inputText: string): { textWithoutCode: string, codeBlocks: JSX.Element[] } {
    // Regex to find code blocks delimited by triple backticks
    const codeBlockRegex = /```([\s\S]+?)```/g;
    const codeBlocks: JSX.Element[] = [];
    let codeBlockIndex = 0;

    const textWithoutCode = inputText.replace(codeBlockRegex, (match, code) => {
        codeBlocks.push(
            <CopyBlock
                key={codeBlockIndex}
                text={code}
                language="js"
                showLineNumbers={false}
                theme={dracula}
                codeBlock
            />
        );
        return `%%CODEBLOCKPLACEHOLDER%%${codeBlockIndex++}`;
    });
    return { textWithoutCode, codeBlocks };
}

function markdownTableToHtml(inputText: string): string {
    // Find the markdown table in the text
    const tableRegex = /(\|.*\|\n)+\|.*\|/;
    const match = inputText.match(tableRegex);

    if (!match) return inputText; // Return original text if no table found

    const tableMarkdown = match[0];
    const rows = tableMarkdown.split('\n').filter(row => row.trim() !== '');

    let htmlTable = '<table style=\'border: 1px solid black;\'>';

    rows.forEach((row, index) => {
        if (row.indexOf('|') === -1) return; // Skip rows without pipes

        // Split each row into cells
        let cells = row.split('|').filter(cell => cell.trim() !== '');

        if (index === 0) {
            // Header row
            htmlTable += '<tr>' + cells.map(cell => `<th>${cell.trim()}</th>`).join('') + '</tr>';
        } else if (index > 1) {
            // Body rows, skipping the separator row
            htmlTable += '<tr>' + cells.map(cell => `<td>${cell.trim()}</td>`).join('') + '</tr>';
        }
    });

    htmlTable += '</table>';

    // Replace the markdown table in the input with the HTML table
    return inputText.replace(tableRegex, htmlTable);
}

function markdownStylesToHtml(inputText: string): string {
    // Regex to match text within square brackets
    const bracketTextRegex = /\[.*?\]/g;

    // Extract text within square brackets and replace with placeholders
    const bracketTexts: string[] = [];
    const textWithPlaceholders = inputText.replace(bracketTextRegex, (match) => {
        bracketTexts.push(match);
        return `PLACEHOLDER${bracketTexts.length - 1}PLACEHOLDER`;
    });

    let processedText = textWithPlaceholders;

    // Bold: **text** or __text__ to <strong>text</strong>
    processedText = textWithPlaceholders.replace(/(\*\*|__)(.*?)\1/g, '<strong>$2</strong>');

    // Italics: *text* or _text_ to <em>text</em>
    processedText = processedText.replace(/(\*|_)(.*?)\1/g, '<em>$2</em>');

    // Underline: ++text++ to <u>text</u>
    processedText = processedText.replace(/\+\+(.*?)\+\+/g, '<u>$1</u>');

    // Strikethrough: ~~text~~ to <del>text</del>
    processedText = processedText.replace(/~~(.*?)~~/g, '<del>$1</del>');

    // Inline Code: `text` to <code>text</code>
    processedText = processedText.replace(/`(.*?)`/g, '<code>$1</code>');

    // Headers: # text to <h1>text</h1>, ## text to <h2>text</h2>, etc.
    processedText = processedText.replace(/^(#{1,6})\s*(.*)$/gm, (match, hashes, text) => {
        const level = hashes.length;
        return `<h${level}>${text.trim()}</h${level}>`;
    });

    // Reinsert the text within square brackets back into the processed text
    const finalText = processedText.replace(/PLACEHOLDER(\d+)PLACEHOLDER/g, (match, p1) => {
        return bracketTexts[parseInt(p1, 10)];
    });

    return finalText;
}

function removeTrailingLinks(inputText: string): string {
    // Regular expression to match <a> tags with <sup> inside, at the end of the string
    const trailingLinksRegex = /(<a\s+[^>]*><sup[^>]*>.*?<\/sup><\/a>\s*)+$/;

    // Remove any trailing <a> tags with <sup> inside
    let result = inputText.replace(trailingLinksRegex, '');

    return result
}

function repositionLinks(inputText: string): string {
    // Regular expression to match <a> tags with <sup> inside at the start of a line,
    // followed by any text (including URLs or normal text)
    const regex = /^(\d+\.\s*)(<a\s+[^>]*><sup[^>]*>.*?<\/sup><\/a>)(\s*)(.*?)(\s*?)$/gm;

    // Function to rearrange the matched groups: move the <a> tag to the end of the line
    const rearrange = (match: string, listNumber: string, aTag: string, space: string, followingText: string) => {
        // Move the <a> tag to the end of the line, after the following text
        return `${listNumber}${followingText.trim()} ${aTag.trim()}`;
    };

    // Replace the occurrences in the input text
    return inputText.replace(regex, rearrange);
}

function reinsertCodeBlocks(fragments: (string | JSX.Element)[], codeBlocks: JSX.Element[]): (string | JSX.Element)[] {
    let answerHtmlParts: (string | JSX.Element)[] = [];
    fragments.forEach((part, index) => {
        if (typeof part === 'string') {
            const subParts = part.split(/(%%CODEBLOCKPLACEHOLDER%%\d+)/g);
            subParts.forEach((subPart: string) => {
                if (subPart.startsWith('%%CODEBLOCKPLACEHOLDER%%')) {
                    const codeBlockIndex = parseInt(subPart.replace('%%CODEBLOCKPLACEHOLDER%%', ''), 10);
                    answerHtmlParts.push(codeBlocks[codeBlockIndex]);
                } else {
                    answerHtmlParts.push(subPart);
                }
            });
        } else {
            answerHtmlParts.push(part);
        }
    });
    return answerHtmlParts;
}

export function parseAnswerToHtml(answer: any, onCitationClicked: (citationFilePath: string) => void): HtmlParsedAnswer {

    const citations: string[] = [];
    const followupQuestions: string[] = [];
    let answerText = '';

    // for the case where answer comes as an object rather than a string
    // check if answer is an object and construct the answerText from its string values
    // the goal is to extract all string values from the answer object and concatenate them into a single string, separating them with newlines.
    if (typeof answer === 'object' && answer !== null) {
        for (const key in answer) {
            if (typeof answer[key] === 'string') {
                answerText += answer[key] + '\n';
            } else if (Array.isArray(answer[key])) {
                answer[key].forEach((item: any) => {
                    if (typeof item === 'string') {
                        answerText += item + '\n';
                    } else if (typeof item === 'object' && item !== null) {
                        for (const innerKey in item) {
                            if (typeof item[innerKey] === 'string') {
                                answerText += item[innerKey] + '\n';
                            }
                        }
                    }
                });
            }
        }
    } else if (typeof answer === 'string') {
        answerText = answer;
    }

    // Extract any follow-up questions that might be in the answer
    let parsedAnswer = answerText.replace(/<<([^>>]+)>>/g, (match: string, content: string) => {
        followupQuestions.push(content);
        return "";
    });

    // parse code blocks separately
    const { textWithoutCode, codeBlocks } = parseCodeBlocks(parsedAnswer);
    parsedAnswer = textWithoutCode;

    // convert pipes table to HTML table
    parsedAnswer = markdownTableToHtml(parsedAnswer);

    // Convert markdown formatting to HTML, excluding text within square brackets
    parsedAnswer = markdownStylesToHtml(parsedAnswer);

    // Remove nested square brackets from citations
    parsedAnswer = parsedAnswer.replace(/\[([^\]]+)\[([^\]]+)\]\]/g, '[$1$2]');

    // Pattern to find markdown link syntax with both http(s) and www.
    const markdownLinkRegex = /\[([^\]]+)\]((?:\((?:https?:\/\/|www\.)[^\)]+\))|(?:(https?:\/\/|www\.)[^\s]+))/g;

    // Replace markdown links with modified markdown link including the URL inside the brackets
    // Essentially move the URL to within square brackets if the URL isn't already present inside the brackets
    parsedAnswer = parsedAnswer.replace(markdownLinkRegex, (match: string, text: string, urlWithParentheses: string, urlWithoutProtocol: string) => {
        // Extract the URL from parentheses if present
        let url = urlWithParentheses.startsWith('(') ? urlWithParentheses.slice(1, -1) : urlWithParentheses;
        // Prepend http:// if the URL starts with www. and is missing a protocol
        if (urlWithoutProtocol && urlWithoutProtocol.startsWith('www.')) {
            url = `http://${urlWithoutProtocol}`;
        }
        return `[${text}::${url}]${url}`;
    });

    // trim any whitespace from the end of the answer after removing follow-up questions
    parsedAnswer = parsedAnswer.trim();

    // /xyz/ denotes literal form of regular expression. g flag ensure match can happen at lastIndex position or later
    // \[\] matches [] as literals. wherein \x takes x as literal not any escaped character
    // () creates a subexpression
    // [^character_group] matches any characters not in the character_group
    // [^\]] matches characters other than ']', \x denotes literal value of x
    // [^\]]+ matches any character other than ] occurring once or more 
    // this looks for pattern of [] in the answer from ChatAPI. where [] may containing any character other than ']'
    // When more than 1 citation exists then each of them is contained in a sepate []. 
    // Therefore this regex splits each citation into a separate part
    const parts = parsedAnswer.split(/\[([^\]]+)\]/g);

    const fragments: (string | JSX.Element)[] = parts.map((part: string, index: number) => {
        if (index % 2 === 0) {
            return part;
        } else {
            let citationIndex: number;
            // This regex removes 'Source:' followed by any number of colons and any number of spaces after that
            let citation = part.replace(/Source:+\s*/i, '');
            if (citations.indexOf(citation) !== -1) {
                citationIndex = citations.indexOf(citation) + 1;
            } else {
                citations.push(citation);
                citationIndex = citations.length;
            }

            const path = citation; //getCitationFilePath(part);

            return (
                <a key={index} title={part} onClick={() => onCitationClicked(path)}>
                    <sup className={styles.sup}>{citationIndex}</sup>
                </a>
            );
        }
    });

    // reinsert code blocks after detecting citations
    const answerHtmlParts = reinsertCodeBlocks(fragments, codeBlocks);

    return {
        answerHtmlParts,
        citations,
        followupQuestions
    };
}
