import {Approaches, Profile} from "../api/models";

export const webProtocols = ["https://", "http://", "ftps://", "sftp://", "ftp://", "ftpes://", "scp://"];
// export const GreetingText = " ! I am your Fonterra ChatGPT Assistant. I am trained to answer queries on Fonterra data. What would you like to know?"
export const GreetingText = " ! I am your Fonterra AI Assistant. What would you like to know?"
export const LLMModelNames = [
    { company: 'Microsoft', models: [{ model: 'azureopenai' , enabled: true}]}
];
export const ProfileList = [
    { key: 'coopgptppl', description: 'Co-opGPT PPL'},
    { key: 'coopgptppl2', description: 'Co-opGPT PPL Langchain'}
];
export const AgentTypeList = [
    { key: 'openaifunctions', description: 'Open AI Functions Agent'},
    { key: 'conversationchat', description: 'Conversation Chat Agent'},
    { key: 'custom', description: 'Custom Agent'},
    { key: 'zeroshot', description: 'Zero Shot Agent' }
]
export const InputCharacterLimit: number = import.meta.env.VITE_APP_INPUT_CHAR_LIMIT || 40000;
export const DefaultProfile: string = "coopgptppl";
export const Max_Interactions: number = 30;
export const Max_Upload_Files: number = 5;
export const ShowPreviewFeatures: string = import.meta.env.VITE_APP_SHOW_PREVIEW_FEATURES || "false";
export const ReleaseNotesUrl: string = import.meta.env.VITE_APP_RELEASE_NOTES_URL || "";
export const FeedbackFormUrl: string = import.meta.env.VITE_APP_FEEDBACK_FORM_URL || "";
export const DefaultTheme: string = "light";
export const Profiles: { [key: string]: Profile } = {
    "safegpt2": { temperature: 0.0,
        llmModelName: "azureopenai",
        gptVersion: "4omni",
        version: "v3",
        nativeSearch : false,
        useSemanticRanker: false,
        agentType: "openaifunctions",
        tools: [{ name: "websearch"}],
        cardIdentifier: "SafeGPT"
    },
    "coopgptppl": { temperature: 0.0,
        llmModelName: "azureopenai",
        gptVersion: "4omni",
        version: "v3",
        nativeSearch : true,
        agentType: "openaifunctions",
        tools: [{ name: "acs"}],
        useSemanticRanker: true,
        cardIdentifier: "CoopGPTPPL"
    },
    "coopgptppl2": { temperature: 0.0,
        llmModelName: "azureopenai",
        gptVersion: "4omni",
        version: "v2",
        approach: Approaches.ReadRetrieveRead,
        nativeSearch : false,
        useSemanticRanker: false,
        agentType: "openaifunctions",
        tools: [{ name: "acs"}],
        cardIdentifier: "CoopGPTPPL"
    },
    "coopgptfdp": { temperature: 0.0,
        llmModelName: "azureopenai",
        gptVersion: "4",
        version: "v2",
        approach: Approaches.ReadRetrieveRead,
        nativeSearch : false,
        useSemanticRanker: false,
        agentType: "openaifunctions",
        tools: [{ name: "databricks"}],
        cardIdentifier: "CoopGPTFDP"
    }
}