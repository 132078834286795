import React, { useState, useRef, useEffect } from "react";
import { Stack } from "@fluentui/react";
import styles from "./AdminPage.module.css";
import { getFeedbackData, disableAPI, getCurrentVersion } from "../../api/api";
import { DownloadButton } from "../../components/DownloadButton";
import { TwoButtonDialog, TwoButtonDialogHandle, ErrorDialog, ErrorDialogHandle } from "../../components/Dialogs";

const flattenObject = (obj: any, parent: string = "", res: any = {}) => {
    for (let key in obj) {
        let propName = parent ? `${parent}.${key}` : key;
        if (typeof obj[key] === "object" && obj[key] !== null) {
            flattenObject(obj[key], propName, res);
        } else {
            res[propName] = obj[key];
        }
    }
    return res;
};

const jsonToCSV = (jsonData: any[]): string => {
    if (jsonData.length === 0) {
        return "";
    }
    const excludeKeys = ["thoughts", "data_points"]; 
    const flatData = jsonData.map(item => flattenObject(item));
    const headers = Array.from(new Set(flatData.flatMap(Object.keys))).filter(header => !excludeKeys.includes(header.split('.')[0])); 
    const csvRows = [];
    csvRows.push(headers.join(","));
    for (const row of flatData) {  
        const values = headers.map(header => {
            const value = row[header];
            const escapedValue = typeof value === "string" ? `"${value.replace(/"/g, '""')}"` : value;
            return escapedValue;
        });
        csvRows.push(values.join(","));
    }
    return csvRows.join("\n");
};

const downloadFile = (data: string | Blob, filename: string, type: string): void => {
    const blob = new Blob([data], { type });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');  
    a.setAttribute('hidden', '');  
    a.setAttribute('href', url);  
    a.setAttribute('download', filename);  
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
};

export const AdminPage: React.FC = () => {
    const [isLoadingCSV, setIsLoadingCSV] = useState(false);
    const [isLoadingJSON, setIsLoadingJSON] = useState(false);
    const [isLoadingPromptMetrics, setIsLoadingPromptMetrics] = useState(false);
    const [reloadRequired, setReloadRequired] = useState(false);
    const [isVisible, setIsVisible] = useState(document.visibilityState === 'visible');
    const isJsonDownloadEnabled = true;
    const isCSVDownloadEnabled = true;
    const isPromptMetricsEnabled = true;
    const versionDialogRef = useRef<TwoButtonDialogHandle | null>(null);
    const errorDialogRef = useRef<ErrorDialogHandle | null>(null);

    const handleDownloadClick = async (format: "csv" | "json" | "promptMetric", setLoading: React.Dispatch<React.SetStateAction<boolean>>) => {
        const fileNames: Record<string, string> = {
          csv: "feedback_data.csv",
          json: "feedback_data.json",
          promptMetric: "prompt_metrics.json",
        }; 

        const mimeTypes: Record<string, string> = {
          csv: "text/csv",
          json: "application/json",
          promptMetric: "application/json",
        };

        try {
            setLoading(true);
            const data = await getFeedbackData(format);
            if (format === "csv") {
                const csvData = jsonToCSV(data);
                downloadFile(csvData, "feedback_data.csv", "text/csv");
            } else if (format === "json" || format === "promptMetric") {
                const jsonString = JSON.stringify(data, null, 2);
                downloadFile(jsonString, fileNames[format], mimeTypes[format]);
            }
        } catch (error: any) {
            // if the error is due to the API being disabled, show a message to the user
            if (error.message === "API is disabled") {
                if (versionDialogRef.current) {
                    versionDialogRef.current.handleClickOpen();
                }
            } else {
                console.error(`Error downloading feedback data:`, error);
            }
        } finally {
            setLoading(false);
        }
    };

    // update the local version to current App Version
    const updateCurrentVersion = async () => {
        try {
            const currentVersion : string = await getCurrentVersion();
            localStorage.setItem('appVersion', currentVersion);
        } catch (error) {
            // catch local storage overflow error
            if (errorDialogRef.current) {
                errorDialogRef.current.handleClickOpen();
            }
        }
    };
    // This will trigger upon reload and re-render! We have to be careful for the case when users navigate away and back again
    // Therefore, we do not enable the API here, but instead update the version and set reload required to false
    // so that if this was a reload the error dialog is disabled and local version updated.
    // If a re-render triggers this, the API will still be disabled until the user reloads, so it is ok to update the version preemptively.
    useEffect(() => {
        setReloadRequired(false);
        updateCurrentVersion();
    }, []);
    
    useEffect(() => {
        if (reloadRequired) {
            if (versionDialogRef.current) {
                versionDialogRef.current.handleClickOpen();
            }
        }
    }, [reloadRequired])

    useEffect(() => {
        function handleVisibilityChange() {
            setIsVisible(document.visibilityState === 'visible');
        }
        document.addEventListener('visibilitychange', handleVisibilityChange);
        return () => {document.removeEventListener('visibilitychange', handleVisibilityChange)};
    }, []);

    useEffect(() => {
        if (isVisible) {
            compareVersion();
        }
    }, [isVisible]);

    const compareVersion = async () => {
        if (document.visibilityState === 'visible') {
            // check the version
            const currentVersion: String = await getCurrentVersion();
            const localVersion: String = localStorage.getItem('appVersion') || "undefined";
            if (currentVersion !== localVersion) {
                // alert the user
                setReloadRequired(true);
            }
        }
    }

    const handleVersionUpdateRequired = (flag : boolean) => {
        if (flag) {
            window.location.reload();
        } else {
            disableAPI();
        }
    }

    return (
        <div>
            <Stack className={styles.Container}>
                <Stack className={styles.MenuContainer} horizontal horizontalAlign="center" tokens={{ childrenGap: 10 }}>
                    {isCSVDownloadEnabled && (
                        <DownloadButton
                            id="downloadCSVButton"
                            onClick={() => handleDownloadClick("csv", setIsLoadingCSV)}
                            isLoading={isLoadingCSV}
                            label="Download CSV Data"
                        />
                    )}
                    {isJsonDownloadEnabled && (
                        <DownloadButton
                            id="downloadJSONButton"
                            onClick={() => handleDownloadClick("json", setIsLoadingJSON)}
                            isLoading={isLoadingJSON}
                            label="Download JSON Data"
                        />
                    )}
                    {isPromptMetricsEnabled && (
                        <DownloadButton
                            id="promptMetricsButton"
                            onClick={() => handleDownloadClick("promptMetric", setIsLoadingPromptMetrics)}
                            isLoading={isLoadingPromptMetrics}
                            label="Prompt Metrics"
                        />
                    )}
                </Stack>
            </Stack>
            <ErrorDialog
                ref={errorDialogRef}
                title="Memory Limit Reached"
                message="The local memory limit has been reached. Please delete a chat to free up memory."
            />
            <TwoButtonDialog 
                ref={versionDialogRef}
                title="Update Required"
                message="Your version of Co-op GPT is out of date, please refresh the page to get the latest version. Don't worry, your saved chats will still be available."
                callback={handleVersionUpdateRequired}
                cancelText="Give me a sec"
                confirmText="Refresh Now"
            />
        </div>
    );
};  
