import React, { useEffect, useState, ComponentType } from 'react';  
import { useNavigate } from 'react-router-dom';  
import { msalInstance } from '../../api/auth';
  
interface ProtectedRouteProps {  
  element: ComponentType<any>;  
  requiredRole: string;  
}  
  
export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ element: Component, requiredRole, ...rest }) => {  
  const [hasAccess, setHasAccess] = useState<boolean>(false);  
  const [checked, setChecked] = useState<boolean>(false);   
  const navigate = useNavigate();  
  
  useEffect(() => {  
    const checkRoles = async () => {  
      const account = msalInstance.getActiveAccount();  
      if (account) {  
        const roles = account.idTokenClaims?.roles || [];  
        // const roles = ['Query.All']; // Hardcoded roles for testing   
        if (roles.includes(requiredRole)) {  
          setHasAccess(true);  
        }  
      }  
      setChecked(true);  
    };  
    checkRoles();  
  }, [requiredRole]);  

  useEffect(() => {  
    if (checked && !hasAccess) {  
      navigate('/', { replace: true });  
    }  
  }, [checked, hasAccess, navigate]);

  return <Component {...rest} />;  
};
