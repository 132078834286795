import React, { forwardRef, useImperativeHandle } from "react";
import { Dialog, DialogType, DialogFooter } from "@fluentui/react/lib/Dialog";
import { PrimaryButton, DefaultButton } from '@fluentui/react/lib/Button';
import { ContextualMenu } from '@fluentui/react/lib/ContextualMenu';
import { useId, useBoolean } from '@fluentui/react-hooks';
import { useTheme } from '../../state/themecontext';

interface DialogProps {
  title: string;
  message: string;
  // dialogType: number;
  callback: (flag: boolean) => void;
  cancelText?: string;
  confirmText?: string;
}

export interface TwoButtonDialogHandle {
  handleClickOpen: () => void;
}

const dragOptions = {
  moveMenuItemText: 'Move',
  closeMenuItemText: 'Close',
  menu: ContextualMenu,
  keepInBounds: true,
};


export const TwoButtonDialog = forwardRef<TwoButtonDialogHandle, DialogProps>((props, ref) => {
  const { title, message, callback, cancelText, confirmText } = props;
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const labelId: string = useId('dialogLabel');
  const subTextId: string = useId('subTextLabel');
  const { themeColors, theme } = useTheme();
  
  const dialogStyles = React.useMemo(() => ({
    main: {
      maxWidth: 450,
      backgroundColor: theme === 'light' ? "#fff" : "#2a2a2a",
    },
    title: {
      color: themeColors.confirmDialogTitleColor,
    },
    subText: {
      color: themeColors.confirmDialogMessageColor,
    },
  }), [theme, themeColors.confirmDialogTitleColor, themeColors.confirmDialogMessageColor]);
  
  const dialogContentProps = {
    type: DialogType.normal,
    title: title,
    closeButtonAriaLabel: 'Close',
    subText: message,
    styles: { // Apply custom styles for title and subText
      title: dialogStyles.title,
      subText: dialogStyles.subText,
    },
  };

  useImperativeHandle(ref, () => ({
    handleClickOpen() {
      setDialogOpen(true);
    }
  }));

  const handleClose = (flag: boolean) => {
    setDialogOpen(false);
    callback(flag);
  };

  const modalProps = React.useMemo(() => ({
    titleAriaId: labelId,
    subtitleAriaId: subTextId,
    isBlocking: false,
    styles: dialogStyles,
    dragOptions: dragOptions,
  }), [labelId, subTextId, dialogStyles]);

  return (
    <>
      <Dialog
        hidden={!dialogOpen}
        onDismiss={() => handleClose(false)}
        dialogContentProps={dialogContentProps}
        modalProps={modalProps}
        styles={dialogStyles} // Apply custom styles to dialog
      >
        <DialogFooter>
          <PrimaryButton
            onClick={() => handleClose(true)}
            text={confirmText || "Continue"}
          />
          <DefaultButton
            onClick={() => handleClose(false)}
            text={cancelText || "Cancel"}
          />
        </DialogFooter>
      </Dialog>
    </>
  );
});
