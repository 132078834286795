import { Stack, IconButton, Text } from "@fluentui/react";
import styles from "./UserChatMessage.module.css";
import React, { useState, useRef, useEffect } from 'react';
import { ShowPreviewFeatures } from "../../config/frontendconfig";
import { UserIcon } from "./UserIcon";

interface Props {
    message: string;
    index: number;
    onMessageEdit: (index: number, newMessage: string) => void;
    isLoading: boolean;
}

// Custom hook for managing textarea resizing
const useAutoResizeTextarea = (initialMessage: string) => {
    // Refs for the textarea and the hidden div used for measuring
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const hiddenDivRef = useRef<HTMLDivElement>(null);
    const [editableMessage, setEditableMessage] = useState(initialMessage);
    const [isEditing, setIsEditing] = useState(false);
    const [initialAdjustment, setInitialAdjustment] = useState(true);
    const minWidth = 180;
    const maxWidth = 640;
    const lineHeight = 22;

    const updateTextareaSize = () => {
        if (textareaRef.current && hiddenDivRef.current) {
            // Calculate and set the width of the textarea
            // const newWidth = Math.min(Math.max(editableMessage.length * 10 + 20, minWidth), maxWidth);
            // textareaRef.current.style.width = `${newWidth}px`;

            // Adjust the height based on the initial adjustment or line count
            let newHeight;
            if (initialAdjustment) {
                // Use the hidden div's scrollHeight for the initial height adjustment
                hiddenDivRef.current.textContent = editableMessage;
                newHeight = hiddenDivRef.current.scrollHeight;
                setInitialAdjustment(false);
            } else {
                // Use line count for subsequent height adjustments
                const lines = Math.ceil(textareaRef.current.scrollHeight / lineHeight);
                newHeight = lines * lineHeight;
            }

            // Set the calculated height on the textarea
            textareaRef.current.style.height = `${newHeight}px`;
        }
    };
    return { textareaRef, hiddenDivRef, editableMessage, setEditableMessage, isEditing, setIsEditing, updateTextareaSize, onEditClicked: () => { setIsEditing(true); setTimeout(updateTextareaSize, 0); } };
};

export const UserChatMessage = ({ message, onMessageEdit, index, isLoading }: Props) => {
    const { textareaRef, hiddenDivRef, editableMessage, setEditableMessage, isEditing, setIsEditing, updateTextareaSize, onEditClicked } = useAutoResizeTextarea(message);

    return (
        <div className={styles.container}>
            {/* Hidden div for measuring the size of the content */}
            <div ref={hiddenDivRef} className={styles.hiddenDiv}>{editableMessage}</div>
            <Stack className={styles.message} tokens={{ childrenGap: 10 }} verticalAlign="start">
                <div className={styles.iconContainer}>
                    <UserIcon/>
                    <Text className={styles.iconText}>{"You"}</Text>
                </div>
                {isEditing ? (
                    <textarea
                        ref={textareaRef}
                        className={styles.editableMessageText}
                        value={editableMessage}
                        onChange={(e) => { setEditableMessage(e.target.value); updateTextareaSize(); }}
                        style={{ height: '44px' }}
                    />
                ) : (
                    <div className={styles.messageText}>{message}</div>
                )}
                {!isEditing && (
                    <Stack horizontal horizontalAlign="start" className={styles.iconsContainer}>
                        <IconButton className={styles.iconButton} iconProps={{ iconName: "Edit" }} title="Edit" ariaLabel="Edit" onClick={onEditClicked} disabled={isLoading}/>
                    </Stack>
                )}
                {isEditing && (
                    <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: 10 }} className={styles.editButtonsContainer}>
                        <button className={styles.saveButton} onClick={() => { setIsEditing(false); onMessageEdit(index, editableMessage); }}>Save & Submit</button>
                        <button className={styles.cancelButton} onClick={() => { setIsEditing(false); setEditableMessage(message); }}>Cancel</button>
                    </Stack>
                )}
            </Stack>
        </div>
    );
};

