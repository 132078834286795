import { Text } from "@fluentui/react";
import { PersonLock24Regular } from "@fluentui/react-icons";
import { useNavigate } from 'react-router-dom';
import styles from "./AdminButton.module.css";
  
interface Props {  
  className?: string;
}  
  
export const AdminButton = ({ className }: Props) => {  
  const navigate = useNavigate();  
  
  return (  
    <div className={`${styles.container} ${className ?? ""}`} 
    onClick={() => navigate('/admin')}
    >  
      <PersonLock24Regular />  
      <Text className={styles.container}>{"Admin"}</Text>  
    </div>  
  );  
};  
