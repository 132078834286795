import { Stack, Text } from "@fluentui/react";
import styles from "./Answer.module.css";
import DOMPurify from "dompurify";
import { AnswerIcon } from "./AnswerIcon";
// import { parseAnswerToHtml } from "./AnswerParser";

interface Props {
    message: string;
}

export const Greeting = ({ message }: Props) => {
    // const parsedAnswer = useMemo(() => parseAnswerToHtml(message), [answer]);

    const sanitizedAnswerHtml = DOMPurify.sanitize(message);
    return (
        <Stack className={`${styles.answerContainer}`} verticalAlign="space-between">
            <Stack.Item>
                <div className={styles.iconContainer}>
                    <AnswerIcon />
                    <Text className={styles.iconText}>{"Co-op GPT"}</Text>
                </div>
            </Stack.Item>

            <Stack.Item grow>
                <div className={styles.answerText} dangerouslySetInnerHTML={{ __html: sanitizedAnswerHtml }}></div>
            </Stack.Item>
        </Stack>
    );
};
