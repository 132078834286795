import { Text } from "@fluentui/react";
import { DocumentAdd24Regular } from "@fluentui/react-icons";

import styles from "./FileUploadButton.module.css";

interface Props {
    className?: string;
    onClick: () => void;
}

export const FileUploadButton = ({ className, onClick }: Props) => {
    return (
        <div className={`${styles.container} ${className ?? ""}`} onClick={onClick}>
            <DocumentAdd24Regular />
            <Text className={styles.container}>{"Upload Files"}</Text>
        </div>
    );
};
