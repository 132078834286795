import React, { forwardRef, useImperativeHandle } from "react";
import { Dialog, DialogType, DialogFooter } from "@fluentui/react/lib/Dialog";
import { PrimaryButton } from '@fluentui/react/lib/Button';
import { ContextualMenu } from '@fluentui/react/lib/ContextualMenu';
import { useId, useBoolean } from '@fluentui/react-hooks';
import { useTheme } from '../../state/themecontext';

interface DialogProps {
  title: string;
  message: string;
}

export interface ErrorDialogHandle {
  handleClickOpen: () => void;
}

const dragOptions = {
  moveMenuItemText: 'Move',
  closeMenuItemText: 'Close',
  menu: ContextualMenu,
  keepInBounds: true,
};


export const ErrorDialog = forwardRef<ErrorDialogHandle, DialogProps>((props, ref) => {
  const { title, message } = props;
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const labelId: string = useId('dialogLabel');
  const subTextId: string = useId('subTextLabel');
  const { themeColors, theme } = useTheme();
  
  const dialogStyles = React.useMemo(() => ({
    main: {
      maxWidth: 450,
      backgroundColor: theme === 'light' ? "#fff" : "#2a2a2a",
    },
    title: {
      color: themeColors.errorDialogTitleColor,
    },
    subText: {
      color: themeColors.errorDialogMessageColor,
    },
  }), [theme, themeColors.errorDialogTitleColor, themeColors.errorDialogMessageColor]);
  
  const dialogContentProps = {
    type: DialogType.normal,
    title: title,
    closeButtonAriaLabel: 'Close',
    subText: message,
    styles: { // Apply custom styles for title and subText
      title: dialogStyles.title,
      subText: dialogStyles.subText,
    },
  };

  useImperativeHandle(ref, () => ({
    handleClickOpen() {
      setDialogOpen(true);
    }
  }));

  const handleClose = () => {
    setDialogOpen(false);
  };

  const closeDialog = () => {
    setDialogOpen(false);
  }

  const modalProps = React.useMemo(() => ({
    titleAriaId: labelId,
    subtitleAriaId: subTextId,
    isBlocking: false,
    styles: dialogStyles,
    dragOptions: dragOptions,
  }), [labelId, subTextId, dialogStyles]);

  return (
    <>
      <Dialog
        hidden={!dialogOpen}
        onDismiss={closeDialog}
        dialogContentProps={dialogContentProps}
        modalProps={modalProps}
        styles={dialogStyles} // Apply custom styles to dialog
      >
        <DialogFooter>
          <PrimaryButton
            onClick={() => handleClose()}
            text="Ok"
          />
        </DialogFooter>
      </Dialog>
    </>
  );
});