
import { PublicClientApplication, EventType } from '@azure/msal-browser';
import { MsalConfig, LoginRequest } from "../config/authconfig";

export const msalInstance = new PublicClientApplication(MsalConfig);
const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
}
msalInstance.addEventCallback((event) => {
    // @ts-ignore
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload?.account){        
        // @ts-ignore
        msalInstance.setActiveAccount(event.payload?.account);
    }
    if (event.eventType === EventType.LOGIN_FAILURE) console.log(JSON.stringify(event));
});

export const acquireAccessToken = async (msalInstance: any) => {
    const activeAccount = msalInstance.getActiveAccount();

    if (!activeAccount && accounts.length === 0) {
        alert('Please login with your Fonterra Domain. Refreshing Page');
        window.location.reload();
    }

    const authResult = await msalInstance.acquireTokenSilent({
        scopes: LoginRequest.scopes,
        account: activeAccount || accounts[0]
    });

    return authResult.accessToken;
};

export const acquireBearerToken = async (msalInstance: any) => {
    const accessToken = await acquireAccessToken(msalInstance);
    return "Bearer " + accessToken;
};