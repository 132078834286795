import React from "react";
import { PrimaryButton, Spinner, SpinnerSize } from "@fluentui/react";

interface DownloadButtonProps {
    id: string;
    onClick: () => void;
    isLoading: boolean;
    label: string;
}

export const DownloadButton: React.FC<DownloadButtonProps> = ({ id, onClick, isLoading, label }) => (
    <PrimaryButton id={id} onClick={onClick} disabled={isLoading}>
        {isLoading ? (
            <>
                <Spinner size={SpinnerSize.small} styles={{ root: { marginRight: 8 } }} />
                Downloading...
            </>
        ) : (
            label
        )}
    </PrimaryButton>
);

