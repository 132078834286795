import React from "react";
import { useMemo, useState, useEffect } from "react"; 
import { Stack, IconButton, Text } from "@fluentui/react";
import DOMPurify from "dompurify";
import styles from "./Answer.module.css";
import { ChatResponse, getCitationFilePath, FeedbackDataType } from "../../api";
import { parseAnswerToHtml } from "./AnswerParser";
import { AnswerIcon } from "./AnswerIcon";
import { useTheme } from '../../state/themecontext';
import { FeedbackPanel } from '../FeedbackPanels';

interface Props {
    answer: ChatResponse;
    isSelected?: boolean;
    onCitationClicked: (filePath: string) => void;
    onThoughtProcessClicked: () => void;
    onSupportingContentClicked: () => void;
    onFollowupQuestionClicked?: (question: string) => void;
    showFollowupQuestions?: boolean;
    onSubmitFeedback: (feedbackDataType: FeedbackDataType) => void; 
    responseID: string;
    activeAnalysisPanelTab: string | undefined;
}

export const Answer = ({
    answer,
    isSelected,
    onCitationClicked,
    onThoughtProcessClicked,
    onSupportingContentClicked,
    onFollowupQuestionClicked,
    showFollowupQuestions,
    onSubmitFeedback,
    responseID,
    activeAnalysisPanelTab,
}: Props) => {
    const { answerHtmlParts, citations, followupQuestions } = useMemo(() => parseAnswerToHtml(answer.answer, onCitationClicked), [answer]);
    const { themeColors } = useTheme();
    const [userFeedback, setUserFeedback] = useState<string>("neutral");
    const [isFeedbackDialogVisible, setIsFeedbackDialogVisible] = useState(false);
    
    useEffect(() => {  
      setUserFeedback(userFeedback ?? "neutral");
    }, [userFeedback]);  
  
    const handleFeedbackType = (type: string,
    ) => {
      if (userFeedback === type) {
        setUserFeedback("neutral");   
      } else {  
        setUserFeedback(type);   
        setIsFeedbackDialogVisible(true);    
      }  
    };

    let contentToRender: (string | JSX.Element)[];
    if (answer.error_code) {
        switch (answer.error_code) {
            case "content_filter_prompt_error":
                contentToRender = ["Query contains hateful, sexual, violent content or content that promotes self-harm. Please rephrase your query."];
                break;
            case "content_filter_response_error":
                contentToRender = ["Some or all of the content has been filtered due to content policy."];
                break;
            case "content_filter_not_applied":
                contentToRender = ["Caution: Content filtering could not be applied."];
                break;
            default:
                contentToRender = answerHtmlParts;
        }
    } else {
        contentToRender = answerHtmlParts;
    }

    const getButtonStyle = (isSelected: boolean, activeTab: string | undefined, tabName: string, iconColor: string) => ({  
        color: isSelected && activeTab === tabName ? "yellow" : iconColor,  
        fill: isSelected && activeTab === tabName ? "yellow" : "white",  
        background: isSelected && activeTab === tabName ? "grey" : themeColors.answerIconBackgroundColor,  
        height: "24px"  
    });  

    return (
        <Stack className={`${styles.answerContainer} ${isSelected && styles.selected}`} verticalAlign="space-between">
            <Stack.Item>
                <Stack horizontal horizontalAlign="space-between">
                    <div className={styles.iconContainer}>
                        <AnswerIcon />
                        <Text className={styles.iconText}>{"Co-op GPT"}</Text>
                    </div>
                    <div>
                        <IconButton
                            style={getButtonStyle(isSelected ?? false, activeAnalysisPanelTab, 'thoughtProcess', themeColors.bulbIconColor)} 
                            iconProps={{ iconName: "Lightbulb" }}
                            title="Show thought process"
                            ariaLabel="Show thought process"
                            onClick={() => onThoughtProcessClicked()}
                            disabled={!answer.thoughts}
                        />
                        <IconButton
                            style={getButtonStyle(isSelected ?? false, activeAnalysisPanelTab, 'supportingContent', themeColors.clipboardIconColor)} 
                            iconProps={{ iconName: "KnowledgeArticle" }}
                            title="Show supporting content"
                            ariaLabel="Show supporting content"
                            onClick={() => onSupportingContentClicked()}
                            disabled={!answer.data_points.length}
                        />
                    </div>
                </Stack>
            </Stack.Item>

            <Stack.Item grow>
                <div className={styles.answerText}>
                    {contentToRender.map((part: string | JSX.Element, index: number) => (
                        <React.Fragment key={index}>
                            {typeof part === 'string' ? (
                                <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(part) }} />
                            ) : (
                                part
                            )}
                        </React.Fragment>
                    ))}
                </div>
            </Stack.Item>

            {!!citations.length && (
                <Stack.Item>
                    <Stack horizontal wrap tokens={{ childrenGap: 5 }}>
                        <span className={styles.citationLearnMore}>Citations:</span>
                        {citations.map((x, i) => {
                            const path = x; // getCitationFilePath(x);
                            return (
                                <a key={i} className={styles.citation} title={x} onClick={() => onCitationClicked(path)}>
                                    {`${++i}. ${x}`}
                                </a>
                            );
                        })}
                    </Stack>
                </Stack.Item>
            )}
            {/* Thumbs Section */}  
            <Stack.Item className={styles.thumbsContainer}>  
                <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 5 }}>  
                <Text className={styles.feedBackText}>{"Are you happy with the response?"}</Text>  
                <div>  
                    <IconButton  
                    className={styles.thumbButton}  
                    iconProps={{ iconName: userFeedback === 'positive' ? 'LikeSolid' : 'Like' }}  
                    title="Thumbs Up"  
                    onClick={() => handleFeedbackType('positive')}  
                    />  
                    <IconButton  
                    className={styles.thumbButton}  
                    iconProps={{ iconName: userFeedback === 'negative' ? 'DislikeSolid' : 'Dislike' }}  
                    title="Thumbs Down"  
                    onClick={() => handleFeedbackType('negative')}
                    />  
                </div>  
                </Stack>  
            </Stack.Item>  

            {!!followupQuestions.length && showFollowupQuestions && onFollowupQuestionClicked && (
                <Stack.Item>
                    <Stack horizontal wrap className={`${!!citations.length ? styles.followupQuestionsList : ""}`} tokens={{ childrenGap: 6 }}>
                        <span className={styles.followupQuestionLearnMore}>Follow-up questions:</span>
                        {followupQuestions.map((x, i) => {
                            return (
                                <a key={i} className={styles.followupQuestion} title={x} onClick={() => onFollowupQuestionClicked(x)}>
                                    {`${x}`}
                                </a>
                            );
                        })}
                    </Stack>
                </Stack.Item>
            )}
            {/* Feedback Page Section */}  
            <FeedbackPanel  
                isVisible={isFeedbackDialogVisible}  
                onClose={() => setIsFeedbackDialogVisible(false)}  
                feedbackType={userFeedback}
                onFeedbackTypeChange={setUserFeedback}
                onSubmitFeedback={onSubmitFeedback}
                responseID={responseID}
            />  
        </Stack>
    );
};
