import { Outlet, NavLink, Link } from "react-router-dom";
import styles from "./Layout.module.css";
import { IconButton } from "@fluentui/react";
import fonterralogo from "../../assets/fonterralogo.png";

import { ReleaseNotesUrl } from "../../config/frontendconfig";

import { useTheme } from '../../state/themecontext';
import { ThemeToggleButton } from "../../components/ThemeToggleButton";

const appVersion = import.meta.env.VITE_APP_VERSION || "Preview 0.5";

const Layout = () => {    
    const { toggleTheme, theme } = useTheme(); 
    return (
        <div className={styles.layout}>
            <header className={styles.header} role={"banner"}>
                <div className={styles.headerContainer} >
                    <Link to="/" className={styles.headerTitleContainer} >
                        <img src={fonterralogo} loading="lazy" alt="fonterra logo" style={{ width: 80, height: 64 }} />
                    </Link> 
                    <Link to="/" className={styles.headerTitleContainer} >
                        <h3 className={styles.headerTitle}>Co-op GPT</h3>
                    </Link>
                    <div className={styles.rightGroup}>
                        <ThemeToggleButton theme={theme} toggleTheme={toggleTheme} />
                        <a href={ReleaseNotesUrl} target="_blank" rel="noopener noreferrer" className={styles.headerVersionContainer}>
                            <h5 className={styles.headerVersion}>{appVersion}</h5>
                        </a>
                    </div>
                </div>
            </header>
            <Outlet />
        </div>
    );
};

export default Layout;
