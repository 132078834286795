import React, { useState } from 'react';  
import styles from "./Feedback.module.css";  
import { FeedbackDataType } from '../../api';
  
interface FeedbackPanelProps {  
    isVisible: boolean;  
    onClose: () => void;  
    feedbackType: string; 
    onFeedbackTypeChange: (type: string) => void; 
    onSubmitFeedback: (FeedbackDataType: FeedbackDataType) => void;
    responseID: string;
}  

const categories = [  
    { value: "Incomplete Response", label: "Incomplete Response" },  
    { value: "Incorrect Response", label: "Incorrect Response" },  
    { value: "Application Error", label: "Application Error" },  
    { value: "Feature Not Working", label: "Feature Not Working" },  
    { value: "Timed out", label: "Timed out" },
];  

const shareOptions = [  
    { value: "yes", label: "Yes" },  
    { value: "no", label: "No" },  
];

const learnMoreLink = "https://fonterra.sharepoint.com/:b:/s/artificialintelligence357/Eer2vFqkuD9JtMEW_fn6PhMB_ge0XF12JHyVBhXMqoLvyw?e=ey8xm6";
  
const FeedbackPanel: React.FC<FeedbackPanelProps> = ({ 
    isVisible, 
    onClose, 
    feedbackType, 
    onSubmitFeedback, 
    responseID, 
    onFeedbackTypeChange,
}) => {
    const [issue, setIssue] = useState('');  
    const [details, setDetails] = useState('');  
    const [sharePrompt, setsharePrompt] = useState('yes');  

    const handleSubmit = async (e: React.FormEvent) => {  
        e.preventDefault();  
  
    const submitFeedbackDataType = {  
        feedbackType: feedbackType,  
        comment: details,  
        sharePrompt,  
        feedbackSubmissionTime: new Date().toISOString(),
        responseID: responseID,
        issue, 
    }; 
  
    try {  
      onSubmitFeedback(submitFeedbackDataType);
        // enable if you want to reset the forms
        // setIssue('') ;
        // setDetails('');
        // setsharePrompt('yes');
    } catch (error) {  
        console.error('There was a problem with the fetch operation:', error);  
    }  
    onClose();  
  };

    const handleCancel = () => {
        onFeedbackTypeChange('Neutral');
        onClose();
    };
  
  if (!isVisible) return null;  
  
    return (
        <div className={styles.feedbackOverlay}>
            <div className={styles.feedbackDialog}>
                <div className={styles.feedbackHeader}>
                    <h2>Thank you for your feedback!</h2>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className={styles.feedbackContent}>
                        {feedbackType === "negative" && (
                            <>
                                <label htmlFor="issue">
                                    What went wrong?<span className={styles.requiredAsterisk}>*</span>
                                </label>
                                <select id="issue" value={issue} onChange={e => setIssue(e.target.value)} className={styles.dropdown} required>
                                    <option value="">Please select from the following options...</option>
                                    {categories.map(category => (
                                        <option key={category.value} value={category.value}>
                                            {category.label}
                                        </option>
                                    ))}
                                </select>
                            </>
                        )}
                        <label htmlFor="details">
                            {feedbackType === "negative" ? (
                                <>
                                    What could we do to improve your Co-op GPT experience:<span className={styles.requiredAsterisk}>*</span>
                                </>
                            ) : (
                                "Take a moment to tell us about your Co-op GPT experience today (optional):"
                            )}
                        </label>
                        <textarea
                            id="details"
                            className={styles.textField}
                            placeholder="Please provide as much detail as you can..."
                            value={details}
                            onChange={e => setDetails(e.target.value)}
                            required
                        />
                        <p>
                            Share my prompt, response, and any uploaded documents to be sent to the Co-op GPT project team for review?{" "}
                            <a href={learnMoreLink} target="_blank" rel="noopener noreferrer" className={styles.customLink}>
                                Learn more about Generative AI at Fonterra
                            </a>
                        </p>
                        <div className={styles.radioGroup}>
                            {shareOptions.map(option => (
                                <label key={option.value}>
                                    <input
                                        type="radio"
                                        name="share"
                                        value={option.value}
                                        checked={sharePrompt === option.value}
                                        onChange={() => setsharePrompt(option.value)}
                                    />
                                    {option.label}
                                </label>
                            ))}
                        </div>
                    </div>
                    <div className={styles.feedbackFooter}>
                        <button type="submit" className={styles.submitButton}>
                            Submit
                        </button>
                        <button type="button" className={styles.closeButtonFooter} onClick={handleCancel}>
                            Cancel
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );  
};  
  
export { FeedbackPanel };  
