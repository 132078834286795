import React, { useState, useCallback, useEffect, useRef } from "react";
import { PrimaryButton, DefaultButton } from "@fluentui/react/lib/Button";
import { Toggle } from '@fluentui/react/lib/Toggle';
import { useDropzone, FileRejection, Accept } from 'react-dropzone';
import styles from "./FileUpload.module.css";
import { Max_Upload_Files } from "../../config/frontendconfig";
import { Dismiss12Regular, CheckmarkCircle12Regular, ArrowLeft20Regular, ArrowRight20Regular } from "@fluentui/react-icons";
import { useTheme } from '../../state/themecontext';
import { uploadFiles, deleteConversationIndex, disableAPI } from "../../api";
import { Spinner, SpinnerSize } from "@fluentui/react";
import { ShowPreviewFeatures } from "../../config/frontendconfig";
import { TwoButtonDialog, TwoButtonDialogHandle } from "../Dialogs/TwoButtonDialog";

interface Props {
    onUpload: (files: File[], isFileUploadPanelOpen: boolean, fileNames: string[]) => void;
    loading: boolean;
    conversationId: string;
    onIndexingComplete: () => void;
    profile: string;
    onIndexDeletion: () => void;
    onClearFiles: () => void;
}

export const FileUploadComponent = ({ loading, onUpload, conversationId, onIndexingComplete, profile, onIndexDeletion, onClearFiles }: Props) => {
    const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
    const [uploadedFiles, setUploadedFiles] = useState<string[]>([]);
    const [fileRejections, setFileRejections] = useState<FileRejection[]>([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [processingType, setProcessingType] = useState<'upload' | 'delete' | null>(null);
    const [uploadSuccess, setUploadSuccess] = useState<boolean>(false);
    const [indexDeletionSuccess, setIndexDeletionSuccess] = useState<boolean>(false);
    const [isPDFSelected, setIsPDFSelected] = useState<boolean>(false);
    const [showGuidance, setShowGuidance] = useState<'csv' | 'pdf' | null>(null);
    const [showPreviewFeatures, setShowPreviewFeatures] = useState<boolean>(ShowPreviewFeatures === "true");
    const versionDialogRef = useRef<TwoButtonDialogHandle | null>(null);

    const { themeColors } = useTheme();
    const dropzoneStyle = {
        '--dropzone-text-color': themeColors.dropzoneTextColor,
        '--dropzone-border-color': themeColors.dropzoneBorderColor,
        '--dropzone-hover-color': themeColors.dropzoneHoverColor,
        '--file-upload-button-active-background-color': themeColors.fileUploadButtonActiveBackgroundColor,
        '--file-upload-button-disabled-background-color': themeColors.fileUploadButtonDisabledBackgroundColor,
        '--file-upload-button-active-text-color': themeColors.fileUploadButtonActiveTextColor,
        '--file-upload-button-disabled-text-color': themeColors.fileUploadButtonDisabledTextColor,
        '--clear-files-button-disabled-background-color': themeColors.clearFilesButtonDisabledBackgroundColor,
        '--clear-files-button-active-text-color': themeColors.clearFilesButtonActiveTextColor,
        '--clear-files-button-disabled-text-color': themeColors.clearFilesButtonDisabledTextColor,
        '--clear-files-button-active-background-color': themeColors.clearFilesButtonActiveBackgroundColor,
        '--file-upload-button-disabled-border-color': themeColors.fileUploadButtonDisabledBorderColor,
        '--close-button-background-color': themeColors.closeButtonBackgroundColor,
        '--close-button-text-color': themeColors.closeButtonTextColor,
        '--guidance-container-title': themeColors.guidanceContainerTitle,
        '--guidance-container-background': themeColors.guidanceContainerBackground,
        '--guidance-container-text-color': themeColors.guidanceContainerTextColor,
        '--guidance-container-link-color': themeColors.guidanceContainerLinkColor,
        '--success-text-color': themeColors.successTextColor,
        textAlign: 'left',
        marginTop: '20px',
        color: themeColors.closeButtonTextColor
    } as React.CSSProperties;

    useEffect(() => {
        const root = document.documentElement;
        root.style.setProperty('--scrollbar-thumb', themeColors.scrollbarThumb);
        root.style.setProperty('--scrollbar-track', themeColors.scrollbarTrack);
    }, [themeColors]);

    // Load uploaded files from local storage when the component mounts
    useEffect(() => {
        const cacheItem = profile + "allConversations";
        const storedConversations = localStorage.getItem(cacheItem);
        if (storedConversations) {
            const conversations = JSON.parse(storedConversations);
            const currentConversation = conversations.find((conv: any) => conv.conversationID === conversationId);
            if (currentConversation?.uploadedFiles) {
                setUploadedFiles(currentConversation.uploadedFiles);
            }
        }
    }, [conversationId, profile]);

    const clearSuccessMessages = () => {
        setUploadSuccess(false);
        setIndexDeletionSuccess(false);
    };

    const onDrop = useCallback((acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
        clearSuccessMessages();
        const totalFiles = selectedFiles.length + acceptedFiles.length;
        if (totalFiles <= Max_Upload_Files) {
            const newFiles = [...selectedFiles, ...acceptedFiles];
            const fileExtensions = new Set(newFiles.map(file => file.name.split('.').pop()?.toLowerCase() || ''));

            if (fileExtensions.size > 1 || fileExtensions.has('')) {
                setErrorMessage("You can only upload one file type at a time!");
            } else {
                setSelectedFiles(newFiles);
                setErrorMessage("");
            }
        } else {
            setErrorMessage(`You can only upload up to ${Max_Upload_Files} files!`);
        }
        setFileRejections(rejectedFiles);
    }, [selectedFiles]);

    const acceptedFiles: Accept = showPreviewFeatures
        ? {
            "application/pdf": [".pdf"],
            "text/csv": [".csv"],
            "application/vnd.ms-excel": [".xls"],
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
            // "application/msword": [".doc"],
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"]
          }
        : {
            "application/pdf": [".pdf"],
            "text/csv": [".csv"],
            "application/vnd.ms-excel": [".xls"],
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
            // "application/msword": [".doc"],
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"]
          };
    
    // Function to extract and format file types
    function getFileTypes(acceptedFiles: Accept): string {
        const fileTypes = Object.values(acceptedFiles).flat();
        return fileTypes.map(ext => ext.replace(".", "").toUpperCase()).join(", ");
    }

    const formattedFileTypes = getFileTypes(acceptedFiles);

    const fileTypesMessage: string = `Supported file types: ${formattedFileTypes}.`;
    const fileRejectionMessage: string = `You can only upload supported file types: ${formattedFileTypes}.`; 

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: acceptedFiles,
        disabled: loading || isProcessing
    });

    const updateLocalStorage = (files: string[]) => {
        const cacheItem = profile + "allConversations";
        const storedConversations = localStorage.getItem(cacheItem);
        if (storedConversations) {
            const conversations = JSON.parse(storedConversations);
            const updatedConversations = conversations.map((conv: any) =>
                conv.conversationID === conversationId ? { ...conv, uploadedFiles: files } : conv
            );
            localStorage.setItem(cacheItem, JSON.stringify(updatedConversations));
        }
    };

    const onConfirmClick = async () => {
        if (selectedFiles.length === 0) return;

        clearSuccessMessages();
        setIsProcessing(true);
        setProcessingType('upload');
        try {
            await uploadFiles(selectedFiles, conversationId);
            const newUploadedFiles = selectedFiles.map(file => file.name);
            setUploadedFiles(newUploadedFiles);
            updateLocalStorage(newUploadedFiles);
            onUpload(selectedFiles, true, newUploadedFiles);
            setSelectedFiles([]);
            setUploadSuccess(true);
            setErrorMessage("");
        } catch (error: any) {
            if (error.message === "API is disabled") {
                if (versionDialogRef.current) {
                    versionDialogRef.current.handleClickOpen();
                }
            } else {
                console.error("Upload failed:", error);
                setErrorMessage("Upload failed. Please try again.");
            }
        } finally {
            setIsProcessing(false);
            setProcessingType(null);
            onIndexingComplete();
        }
    };

    const onClearClick = async () => {
        clearSuccessMessages();
        if (uploadedFiles.length > 0) {
            setIsProcessing(true);
            setProcessingType('delete');
            try {
                await deleteConversationIndex(conversationId);
                setUploadedFiles([]);
                updateLocalStorage([]);
                setIndexDeletionSuccess(true);
                onIndexDeletion();
                onClearFiles();
            } catch (error: any) {
                if (error.message === "API is disabled") {
                    if (versionDialogRef.current) {
                        versionDialogRef.current.handleClickOpen();
                    }
                }
                console.error("Index deletion failed:", error);
                setErrorMessage("Failed to clear files. Please try again.");
            } finally {
                setIsProcessing(false);
                setProcessingType(null);
            }
        }
        setSelectedFiles([]);
    };

    const handleVersionUpdateRequired = (flag : boolean) => {
        if (flag) {
            window.location.reload();
        } else {
            disableAPI();
        }
    }

    return (
        <div style={dropzoneStyle}>
            <div {...getRootProps()} className={`${styles.dropzone} ${isDragActive ? styles.active : ''} ${isProcessing ? styles.disabled : ''}`}>
                <input {...getInputProps()} />
                <p>{isProcessing ? "File processing in progress..." : "Drag and drop files here, or click to select files"}</p>
            </div>
            <div className={styles.fileListsContainer}>
                {uploadedFiles.length > 0 && (
                    <div className={styles.fileListSection}>
                        <div className={`${styles.selectedFilesHeader} ${styles.currentUploadedFilesHeader}`}>File Context:</div>
                        {uploadedFiles.map((fileName, index) => (
                            <div key={index} className={styles.fileItem}>
                                {fileName}
                                <div className={styles.iconWrapper}>
                                    <div className={styles.checkmarkIconContainer} title="Uploaded file">
                                        <CheckmarkCircle12Regular />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
                {selectedFiles.length > 0 && (
                    <div className={styles.fileListSection}>
                        <div className={`${styles.selectedFilesHeader} ${styles.filesToBeUploadedHeader}`}>Selected Files:</div>
                        {selectedFiles.map((file, index) => (
                            <div key={index} className={styles.fileItem}>
                                {file.name}
                                <div className={styles.iconWrapper}>
                                    <div
                                        className={`${styles.iconContainer} ${isProcessing ? styles.disabled : ''}`}
                                        title={isProcessing ? "Processing in progress" : "Remove file"}
                                        onClick={() => !isProcessing && setSelectedFiles(prev => prev.filter((_, i) => i !== index))}
                                    >
                                        <Dismiss12Regular />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
                {fileRejections.length > 0 && fileRejections.map(({ file, errors }, index) => (
                    <div key={index} className={styles.fileRejection}>
                        Cannot upload {file.name}
                    </div>
                ))}
                {fileRejections.length > 0 && (
                    <div className={styles.fileRejectionMessage}>
                        {fileRejectionMessage}
                    </div>
                )}
                {errorMessage && <div className={styles.errorMessage}>{errorMessage}</div>}
            </div>
            <div className={styles.processingContainer}>
                {isProcessing && <Spinner size={SpinnerSize.large} label="Processing files..." />}
            </div>
            {!isProcessing && uploadSuccess && (
                <div className={styles.successMessage}>
                    File content is now available to be queried.
                </div>
            )}
            {!isProcessing && indexDeletionSuccess && (
                <div className={styles.successMessage}>
                    All files have been removed.
                </div>
            )}
            {selectedFiles.length > 0 && !isProcessing && (
                <div className={styles.uploadInstructionsContainer}>
                    Click the upload button to upload and replace file context
                </div>
            )}
            <div className={styles.uploadContainer}>
                <PrimaryButton
                    className={styles.fileUploadButton}
                    text={processingType === 'upload' ? "Uploading..." : "Upload"}
                    onClick={onConfirmClick}
                    disabled={selectedFiles.length === 0 || isProcessing}
                    iconProps={processingType === 'upload' ? { iconName: 'Sync' } : undefined}
                />
                <DefaultButton
                    className={styles.clearFilesButton}
                    text={processingType === 'delete' ? "Clearing files..." : "Clear all files"}
                    onClick={onClearClick}
                    disabled={(!uploadedFiles.length && !selectedFiles.length) || isProcessing}
                    iconProps={processingType === 'delete' ? { iconName: 'Sync' } : undefined}
                />
            </div>
            <br />
            {selectedFiles.some(file => file.type === "application/pdf") &&
                <div className={styles.uploadContainer}>
                    <div className={styles.instructionText}>PDFs may take a long time to process, your patience is appreciated</div>
                </div>
            }
            <div className={styles.guidanceContainer}>
                <h2>File Upload Guidance</h2>
                <div className={styles.toggleWithLabels}>
                    <span className={styles.leftLabel}>Excel / CSV</span>
                    <Toggle
                        disabled={false}
                        label=""
                        checked={isPDFSelected}
                        onChange={(e, checked) => setIsPDFSelected(!!checked)}
                    />
                    <span className={styles.rightLabel}>PDF / DOCX</span>
                </div>
                {isPDFSelected ? (
                    showGuidance === 'pdf' ? (
                        <>
                            <h2>PDF Prompt Examples</h2>
                            <p>Prompt examples for information within the example PDF file below.</p>
                            <a
                                href="https://fonterra.sharepoint.com/:b:/s/artificialintelligence357/Eer2vFqkuD9JtMEW_fn6PhMB_ge0XF12JHyVBhXMqoLvyw?e=ey8xm6"
                                target="_blank"
                                rel="noopener noreferrer"
                                className={styles.clickableText}
                            >
                                For an example PDF click here
                            </a>
                            <ol className={styles.examplesList}>
                                <li>Generate 3-5 examples of good prompts to help me understand how to use generative AI tools effectively. Base the examples on the uploaded document and provide the answers as bullet points.</li>
                                <li>As an analyst I am wanting to understand how to use generative AI in my role. I would like to know the key points from the uploaded document so I can use AI to be more effective at work.</li>
                                <li>Summarise the uploaded document to help me understand how to use generative AI tools effectively. Include all relevant information on Co-op GPT.</li>
                            </ol>
                            <div className={`${styles.centeredTextWrapper} ${styles.backTextShiftLeft}`}>
                                <p className={`${styles.clickableText} ${styles.flexCenter}`} onClick={() => setShowGuidance(null)}>
                                    <ArrowLeft20Regular /> Back
                                </p>
                            </div>
                        </>
                    ) : (
                        <>
                            <p>When uploading PDF/Word files, remember that:</p>
                            <ul>
                                <li>The file's name shouldn't include any of the following special characters: \ / : * ? {'<'} {'>'} | % $ # @ & + =</li>
                                <li>Only documents written in English are supported.</li>
                                <li>Only text-based information is supported i.e. information in graphs and pictures won't be included.</li>
                                <li>
                                    Be sure to ask specific questions. Prompts like "Summarise [document title]" or "Compare [document title] with [document title]" are too generic and won't work.
                                </li>
                                <li>Maximum of 40MB across 5 files.</li>
                                <li>Only .docx format MS Word documents are supported. Please save any .doc formatted documents as .docx before uploading.</li>
                            </ul>
                            <div className={styles.centeredTextWrapper}>
                                <p className={`${styles.clickableText} ${styles.flexCenter}`} onClick={() => setShowGuidance('pdf')}>
                                    Click here to see examples <ArrowRight20Regular />
                                </p>
                            </div>
                        </>
                    )
                ) : (
                    showGuidance === 'csv' ? (
                        <>
                            <h2>Excel / CSV Prompt Examples</h2>
                            <p>Prompt examples for information within the example file below.</p>
                            <a
                                href="https://fonterra.sharepoint.com/:x:/s/artificialintelligence357/ESLEJOGOscxAvnjaylcJySMBET1HGqDCZyV4jwSNmQvg_g?e=misCPO"
                                target="_blank"
                                rel="noopener noreferrer"
                                className={styles.clickableText}
                            >
                                For an example file click here
                            </a>
                            <ol className={styles.examplesList}>
                                <li>This document includes feedback information. Provide three key themes from the feedback on the PowerPoint presentation so I can improve future presentations. Focus on the comments in the "Please provide your feedback on the PowerPoint presentation" section. Return your response as bullet points.</li>
                                <li>This document includes feedback information. As a consultant, I'm wanting feedback on my presentation skills. Based on the feedback, provide three ideas for what I can do to improve my presentation skills so I can clearly convey my points.</li>
                                <li>This document includes feedback information. As a sales representative, I have just given a presentation on Q4 forecasts and am looking for critique on my presentation abilities. Based on the feedback on the PowerPoint presentation, recommend five things I can do to improve future presentations.</li>
                            </ol>
                            <div className={`${styles.centeredTextWrapper} ${styles.backTextShiftLeft}`}>
                                <p className={`${styles.clickableText} ${styles.flexCenter}`} onClick={() => setShowGuidance(null)}>
                                    <ArrowLeft20Regular /> Back
                                </p>
                            </div>
                        </>
                    ) : (
                        <>
                            <p>When uploading Excel or CSV files, it's important to:</p>
                            <ul>
                                <li>Structure the file in the correct way.</li>
                                <li>Use the correct prompts.</li>
                                <li>Give each file at least one minute to upload.</li>
                            </ul>
                            <p>This will allow you to query information within the file.</p>
                            <h3>File guidance</h3>
                            <ul>
                                <li>Each column within your file must have a header/title row.</li>
                                <li>Maximum of 40MB across five files.</li>
                                <li>Ensure each cell contains one answer. Do not have answers split across multiple cells.</li>
                            </ul>
                            <h3>Prompting guidance</h3>
                            <ul>
                                <li>In your prompt, directly reference the title of the document, column header or a prominent phrase within the file.</li>
                                <li>Use terms like 'in the uploaded file' or 'in the document'.</li>
                                <li>Be specific about what you're trying to achieve.</li>
                                <li>Give Co-op GPT context on what the file contains, i.e., 'This file includes information on [topic]'</li>
                                <li>Treat each prompt as a new conversation.</li>
                            </ul>
                            <div className={styles.centeredTextWrapper}>
                                <p className={`${styles.clickableText} ${styles.flexCenter}`} onClick={() => setShowGuidance('csv')}>
                                    Click here to see examples <ArrowRight20Regular />
                                </p>
                            </div>
                        </>
                    )
                )}
            </div>
            <TwoButtonDialog 
                ref={versionDialogRef}
                title="Update Required"
                message="Your version of Co-op GPT is out of date, please refresh the page to get the latest version. Don't worry, your saved chats will still be available."
                callback={handleVersionUpdateRequired}
                cancelText="Give me a sec"
                confirmText="Refresh Now"
            />
        </div>
    );
};