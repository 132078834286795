export const MsalConfig = {
    auth: {
        clientId: import.meta.env.VITE_APP_AUTH_CLIENT_ID || "",
        authority: import.meta.env.VITE_APP_AUTH_AUTHORITY || "https://login.microsoftonline.com/fonterra.onmicrosoft.com",
        redirectUri: '/',
        // Follow below to avoid reloading page upon login
        // https://learn.microsoft.com/en-us/azure/active-directory/develop/msal-js-avoid-page-reloads
        postLogoutRedirectUri: '/',
        navigateToLoginRequestUrl: false
    },
    cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: false
    }
};

export const LoginRequest = {
    scopes: [ import.meta.env.VITE_APP_API_SCOPES || "user_impersonation" ]
};