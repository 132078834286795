import React from 'react';
import { ArrowRepeatAll24Regular } from "@fluentui/react-icons";
import styles from './FlipCard.module.css';
import ReactCardFlip from 'react-card-flip';
import { useNavigate } from 'react-router-dom';

interface NavigateProps {
    pathname: string;
    state?: {
        cardIdentifier?: string;
    };
}

interface FlipCardProps {
    cardStyle: string;
    isFlipped: boolean;
    handleFlip: (e: React.MouseEvent) => void;
    title: string;
    description: string;
    backDescription: string;
    navigateTo: string | NavigateProps;
    disabled?: boolean;
}

export const FlipCard: React.FC<FlipCardProps> = ({ cardStyle, isFlipped, handleFlip, title, description, navigateTo, disabled, backDescription }) => {
    const navigate = useNavigate();

    const navigateToPage = () => {
        if (typeof navigateTo === "object" && navigateTo.pathname) {
            navigate(navigateTo.pathname, { state: navigateTo.state });
        } else {
            navigate(navigateTo as string);
        }
    };

    return (
        <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal">
            {/* Front Side */}
            <div className={`${styles.card} ${cardStyle} ${disabled ? styles.disabledCard : ''}`}>
            <div className={`${styles.textContainer} ${disabled ? styles.disabledText : ''}`} onClick={navigateToPage}>
                    <div className={styles.title}>{title}</div>
                    <div className={styles.description} dangerouslySetInnerHTML={{ __html: description }} />
                </div>
                <div className={styles.flipContainer} onClick={handleFlip}>
                    <span className={styles.flipText}>Click to flip</span>
                    <ArrowRepeatAll24Regular className={styles.flipButton} />
                </div>
            </div>

            {/* Back Side */}
            <div className={`${styles.card} ${cardStyle} ${disabled ? styles.disabledCard : ''}`}>
                <div className={`${styles.textContainer} ${disabled ? styles.disabledText : ''}`} onClick={navigateToPage}>
                    <div className={styles.title}>{title}</div>
                    <div className={styles.description} dangerouslySetInnerHTML={{ __html: backDescription }} />
                </div>
                <div className={styles.flipContainer} onClick={handleFlip}>
                    <span className={styles.flipText}>Click to flip</span>
                    <ArrowRepeatAll24Regular className={styles.flipButton} />
                </div>
            </div>
        </ReactCardFlip>
    );
    
};

export default FlipCard;