import { Stack, PrimaryButton, Text } from "@fluentui/react";
import { ErrorCircle24Regular } from "@fluentui/react-icons";
import { AnswerIcon } from "./AnswerIcon";
import styles from "./Answer.module.css";

interface Props {
    error: string;
    error_code?: string; 
    onRetry: () => void;
}

export const AnswerError = ({ error, error_code, onRetry }: Props) => {
    return (
        <Stack className={styles.answerContainer} verticalAlign="space-between">
            <div className={styles.iconContainer}>
                <AnswerIcon />
                <Text className={styles.iconText}>{"Co-op GPT"}</Text>
            </div>
            <ErrorCircle24Regular aria-hidden="true" aria-label="Error icon" primaryFill="red" />

            <Stack.Item grow>
                <p className={styles.answerText}>{error}</p>
            </Stack.Item>

            {!error_code && ( 
                <PrimaryButton className={styles.retryButton} onClick={onRetry} text="Retry" />
            )}
        </Stack>
    );
};
