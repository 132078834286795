import React from "react";
import { MsalProvider } from '@azure/msal-react';
import ReactDOM from "react-dom/client";
import { HashRouter, Routes, Route } from "react-router-dom";
import { initializeIcons } from "@fluentui/react";
import { msalInstance } from "./api/auth"
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { LoginRequest } from "./config/authconfig"
import { ThemeProvider } from "./state/themecontext"; 

import "./index.css";

import Layout from "./pages/layout/Layout";
import NoPage from "./pages/NoPage";
import Chat from "./pages/chat/Chat";
import Landing from "./pages/landing/Landing";
import { AdminPage } from "./pages/AdminPage/AdminPage";
import { ProtectedRoute } from "./pages/AdminPage/ProtectedRoute";

initializeIcons();


export default function App() {
    const authRequest = { ...LoginRequest };
    return (
        <MsalProvider instance={msalInstance}>
            <HashRouter>
                {/* This component is required to kick authentication and initiate AD login */}
                <MsalAuthenticationTemplate authenticationRequest={authRequest} interactionType={InteractionType.Redirect}>
                    <Routes>
                        <Route path="/" element={<Layout />}>
                            <Route index element={<Landing />} />
                            <Route path="chat" element={<Chat />} />
                            <Route path="admin" element={<ProtectedRoute element={ AdminPage } requiredRole="Manage.All" />} /> 
                            <Route path="*" element={<NoPage />} />
                        </Route>
                    </Routes>
                </MsalAuthenticationTemplate>
            </HashRouter>

        </MsalProvider>
    );
}

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
    <React.StrictMode>
        <ThemeProvider> 
            <App />
        </ThemeProvider>
    </React.StrictMode>
);
